import React, { useCallback } from 'react';
import base64 from 'react-native-base64';
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { stopAITeacherListening } from 'store/communication/actions/speechToTextServiceActions';
import World from 'components/World';
import { Platform } from 'react-native';
import LiveKitSDK from './LiveKitSDK';
import { Chat, LiveKitRoom, RoomAudioRenderer} from '@livekit/components-react';
import useFetchTokenLiveKit from 'hooks/useFetchTokenLiveKit';
const LIVEKIT_WS_URL = "wss://ntt.backend.ivault.ngrok.app";
// const LIVEKIT_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MjY4NTA4MDYsImlzcyI6IkFQSWlqa25GU3hxVkhNSDkxOTE5MTlzazlkOTgyNGpmNzhnaSIsIm5hbWUiOiJEdXJnZXNoIiwibmJmIjoxNzI2MTMwODA2LCJzdWIiOiJEdXJnZXNoIiwidmlkZW8iOnsicm9vbSI6InFyb29tMTEyMyIsInJvb21Kb2luIjp0cnVlfX0.cO2mpEwIqzKbl5n7d0vzJ9KbnphmwOALC7dOWfoEae8";


export const SpeechToTextService = React.memo(() => {
    const { token: LIVEKIT_TOKEN, error, loading, getToken } = useFetchTokenLiveKit();
    const liveKitRoom = React.useRef();
    
    const [audioStream, setAudioStream] = useState(null);
    const audioContext = useRef(null);
    const audioSourceNode = useRef(null);
    const scriptProcessorNode = useRef(null);
    const bufferSize = 4096; // Adjust the buffer size as needed

    const courseInfo = useSelector(state => state.app.courseInfo);
    const courseTokenValues = useSelector(state => state.app.courseTokenValues);

    const AITeacherListening = useSelector(state => state.communication.speechToTextService.AITeacherListeningStatus);
    const oneToOneMeetingStatus = useSelector(state => state.communication.speechToTextService.oneToOneMeetingStatus);

    const [websocket, setWebsocket] = useState(null);
    const [websocketIntermediateResponse, setWebsocketIntermediateResponse] = useState('');
    const [websocketFinalResponse, setWebsocketFinalResponse] = useState('');
    const wsUrl = 'wss://cloud.fluentworlds.com:8009/';

    const dispatch = useDispatch();
    const getTokenMemoized = useCallback(() => {
        getToken({
            ...courseInfo,
            courseId: courseTokenValues.courseId,
            userId: courseTokenValues.userId,
        });
    }, [courseInfo, courseTokenValues, getToken]);

    useEffect(() => {
        if (courseInfo) {
            getTokenMemoized()
            console.log('oneToOneMeetingStatus getToken', oneToOneMeetingStatus);
        }
        return () => {
        }
    }, [courseInfo, oneToOneMeetingStatus])

    useEffect(() => {
        console.error(AITeacherListening);
        async function getMicrophoneStream() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                setAudioStream(stream);
            } catch (error) {
                console.error('Error accessing microphone:', error);
            }
        }

        if (AITeacherListening) {
            getMicrophoneStream();
        }

        if (audioStream && !AITeacherListening) {
            audioStream.getTracks().forEach(track => track.stop());
        }

    }, [AITeacherListening]);

    useEffect(() => {

        if (audioStream) {
            audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
            audioSourceNode.current = audioContext.current.createMediaStreamSource(audioStream);
            scriptProcessorNode.current = audioContext.current.createScriptProcessor(bufferSize, 1, 1);

            scriptProcessorNode.current.onaudioprocess = (event) => {
                const inputData = event.inputBuffer.getChannelData(0);

                if (websocket && AITeacherListening) {

                    const resampledData = resample(inputData, audioContext.current.sampleRate, 16000)
                    const resampled16BitData = floatTo16BitPCM(resampledData);
                    console.log("Sending");
                    if (websocket.readyState == websocket.OPEN && websocket.readyState != websocket.CONNECTING && websocket.readyState != websocket.CLOSING) {
                        websocket.send(resampled16BitData);
                    }

                    console.log("Sent!")
                }

            }

            audioSourceNode.current.connect(scriptProcessorNode.current);
            scriptProcessorNode.current.connect(audioContext.current.destination);

        };

        return () => {
            if (audioContext.current) {
                audioContext.current.close();
            }
        };
    }, [audioStream, websocket, AITeacherListening]);

    useEffect(() => {
        if (websocket == null) {
            var ws = new WebSocket(wsUrl);
            setWebsocket(ws);
        }
        else {
            return;
        }

        ws.onopen = () => {
            // Send the initial configuration to the WebSocket server
            const config = {
                type: 'start',
                language: 'en-US',
                format: 'raw',
                encoding: 'LINEAR16',
                sampleRateHz: 16000,
            };
            if (ws.readyState == ws.OPEN && ws.readyState != ws.CONNECTING && ws.readyState != ws.CLOSING) {
                ws.send(JSON.stringify(config));
            }
        };

        ws.onmessage = (event) => {
            // Handle WebSocket server responses
            var webSocketResponse = event.data;
            setWebsocketIntermediateResponse(webSocketResponse);

        };

        ws.onclose = () => {
            // Handle WebSocket connection closure
            console.log("web socket closed");
            setWebsocket(null);
        };

    }, [wsUrl, websocket]);

    useEffect(() => {
        // if the webResponse is of type end, do not set the response to final response
        // and just use last set final response.
        if (websocketIntermediateResponse === "\{\"type\":\"end\",\"reason\":\"Recognition complete\"}") {
            dispatch(stopAITeacherListening());
            const webSocketEncodedFinalResponse = base64.encode(websocketFinalResponse);
            World.runCommand("process_stt_final_response " + webSocketEncodedFinalResponse);
            websocket.close();
        } else {
            setWebsocketFinalResponse(websocketIntermediateResponse);
        }
    }, [websocketIntermediateResponse])

    // Resample audio chunk function
    function resample(data, inRate, outRate) {
        var fitCount = Math.round(data.length * (outRate / inRate));
        var newData = new Array();
        var springFactor = new Number((data.length - 1) / (fitCount - 1));
        newData[0] = data[0];
        for (var i = 1; i < fitCount - 1; i++) {
            var tmp = i * springFactor;
            var before = new Number(Math.floor(tmp)).toFixed();
            var after = new Number(Math.ceil(tmp)).toFixed();
            var atPoint = tmp - before;
            newData[i] = linearInterpolate(data[before], data[after], atPoint);
        }
        newData[fitCount - 1] = data[data.length - 1];
        return newData;
    }

    function linearInterpolate(before, after, atPoint) {
        return before + (after - before) * atPoint;
    }

    function floatTo16BitPCM(input) {
        let output = new Int16Array(input.length);
        for (let i = 0; i < input.length; i++) {
            let s = Math.max(-1, Math.min(1, input[i]));
            output[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
        }
        return output;
    }

    const onDeviceFailure = (e) => {
        console.error(e);
        alert(
            'Error acquiring camera or microphone permissions. Please make sure you grant the necessary permissions in your browser and reload the tab',
        );
    };
    console.log('oneToOneMeetingStatus', oneToOneMeetingStatus, LIVEKIT_TOKEN);


    if (Platform.OS === "web") {
        return (
            <LiveKitRoom
                ref={liveKitRoom}
                token={LIVEKIT_TOKEN}
                serverUrl={LIVEKIT_WS_URL}
                connect={oneToOneMeetingStatus}
                video={false}
                audio={true}
                onDeviceFailure={onDeviceFailure}
                onConnected={() => {
                    console.log('LiveKit Room connected');
                }}
                onDisconnected={() => {
                    console.log('LiveKit Room disconnected');
                    getTokenMemoized()

                }}>
                <LiveKitSDK />
                <RoomAudioRenderer />
            </LiveKitRoom >
        )
    }
    return (
        <>
        </>
    );
});
